import * as THREE from 'three'
import GUI from 'lil-gui'
import gsap from 'gsap'
//include scrolltrigger into the project:
import ScrollTrigger from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

const isMobile = window.innerWidth < 768
/**
 * Debug
 */
const gui = new GUI()

const parameters = {
    particlesColor: '#8785ff',
    backgroundColor: '#1D2B5D',
    sizeCube: 1.5,
    colorStart: '#764FE6',
    colorFinish: '#2559A6'
}


gui
    .addColor(parameters, 'particlesColor')
    .onChange(() => {
        particlesMaterial.color.set(parameters.particlesColor)
    }
    )

gui
    .addColor(parameters, 'backgroundColor')
    .onChange(() => {
        document.querySelector('html').setAttribute("style", `background:${parameters.backgroundColor}`)
    }
    )

gui.add(parameters, 'sizeCube').min(0.75).max(3).step(0.1).onChange(() => {
    cube.children.forEach(mesh => {
        mesh.scale.set(parameters.sizeCube, parameters.sizeCube, parameters.sizeCube)
    })
}
)

//leaving everything else in case of debugging
gui.hide()


/**
 * Base
 */
// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()


/**
 * TEXTURES
 */
//Texture
//Checking videos first:
let videosReady = false;
let texturesReady = false;
let sceneReady = false;

let texture;
let loadingManager;
let textureLoader;

const projectTextures = [];

const initTextures = async () => {
    // Loading Manager
    loadingManager = new THREE.LoadingManager();
    loadingManager.onLoad = () => {
        console.log('All textures loaded');
    };
    //updating progressbar:
    loadingManager.onProgress = (url, itemsLoaded, itemsTotal) => {
        const progress = itemsLoaded / itemsTotal;
        document.documentElement.style.setProperty('--progress', `${progress * 40 + 60}%`);
    };

    // Use loadingManager for textureLoader
    textureLoader = new THREE.TextureLoader(loadingManager);

    texture = textureLoader.load('textures/gradients/3.jpg')
    texture.magFilter = THREE.NearestFilter

    //Ella Gaublomme portfolio array
    const ellaPortfolio = [];
    for (let i = 1; i < 7; i++) {
        const texture = textureLoader.load(`textures/projects/ella-portfolio/${i}.webp`)
        ellaPortfolio.push(texture)
    }

    //Genom array
    const genom = [];
    for (let i = 1; i < 7; i++) {
        const texture = textureLoader.load(`textures/projects/genom/${i}.webp`)
        genom.push(texture)
    }

    //Scapino array
    const scapino = [];
    //to have video after the images one by one:
    const textureImage1 = textureLoader.load(`textures/projects/scapino/1.webp`)
    const textureImage2 = textureLoader.load(`textures/projects/scapino/2.webp`)
    const textureImage3 = textureLoader.load(`textures/projects/scapino/3.webp`)


    if (!isMobile) {
        //video texture
        const videos = document.querySelectorAll('video');
        videos.forEach(video => {
            if (video.readyState >= 2) {
                // The video is loaded and ready to play
                video.play();
            }
        })

        let textureVideo1, textureVideo2, textureVideo3;

        textureVideo1 = new THREE.VideoTexture(videos[0]);
        textureVideo1.minFilter = THREE.LinearFilter;
        textureVideo1.magFilter = THREE.LinearFilter;

        textureVideo2 = new THREE.VideoTexture(videos[1]);
        textureVideo2.minFilter = THREE.LinearFilter;
        textureVideo2.magFilter = THREE.LinearFilter;

        textureVideo3 = new THREE.VideoTexture(videos[2]);
        textureVideo3.minFilter = THREE.LinearFilter;
        textureVideo3.magFilter = THREE.LinearFilter;

        scapino.push(textureImage1, textureVideo3, textureImage2, textureVideo1, textureImage3, textureVideo2)
    } else {
        scapino.push(textureImage1, textureImage1, textureImage2, textureImage2, textureImage3, textureImage3)
    }

    //Train-world array
    const trainWorld = [];
    const textureTrain = textureLoader.load(`textures/projects/train-world/1.webp`)
    for (let i = 1; i < 7; i++) {
        trainWorld.push(textureTrain)
    }

    //Api Weather array
    const apiWeather = [];
    for (let i = 1; i < 7; i++) {
        const texture = textureLoader.load(`textures/projects/weather/${i}.webp`)
        apiWeather.push(texture)
    }

    projectTextures.push(ellaPortfolio, genom, scapino, trainWorld, apiWeather);


    projectTextures.forEach(project => {
        project.forEach(texture => {
            texture.colorSpace = THREE.SRGBColorSpace
        }
        )
    })

    return texturesReady = true;
}

// Function to check all assets ready
const checkAllAssetsReady = async () => {
    await initTextures();
    if (videosReady && texturesReady) {
        // Hide the preloader
        const preloader = document.querySelector('#preloader');
        if (preloader) {
            preloader.style.display = 'none';
        }
    }
}

// Function to check video readiness
const checkVideosReady = async () => {
    let readyCount = 0;
    const videos = document.querySelectorAll('video');
    //for mobile devices:
    if (isMobile) {
        videos.forEach(video => {
            //don't load videos on mobile devices
            video.remove();
        })
        videosReady = true;
        checkAllAssetsReady();
    }

    videos.forEach(video => {
        if (video.readyState > 3) {
            readyCount++;
            document.documentElement.style.setProperty('--progress', `${readyCount * 20}%`);
            if (readyCount === videos.length) {
                console.log('All videos are ready');
                videosReady = true;
                checkAllAssetsReady();
            }
        } else {
            video.addEventListener('canplaythrough', () => {
                readyCount++;
                document.documentElement.style.setProperty('--progress', `${readyCount * 20}%`);
                if (readyCount === videos.length) {
                    console.log('All videos are ready');
                    videosReady = true;
                    checkAllAssetsReady();
                }
            }, { once: true }
            );
        }
    });
}

checkVideosReady();





/**
 * GEOMETRY
 */
const objectsDistance = 4


//creating cube from planes:
const cube = new THREE.Group()
scene.add(cube)

const initCube = () => {
    for (let i = 0; i < 6; i++) {
        const mesh = new THREE.Mesh(
            new THREE.PlaneGeometry(1.5, 1.5),
            new THREE.MeshStandardMaterial({ map: projectTextures[0][i] })
        )
        cube.add(mesh)
    }

    // Front
    cube.children[0].position.z = parameters.sizeCube * 0.5; // Move to front face

    // Back
    cube.children[1].rotation.y = Math.PI; // Rotate 180 degrees
    cube.children[1].position.z = -parameters.sizeCube * 0.5; // Move to back face

    // Right
    cube.children[2].rotation.y = Math.PI / 2; // Rotate 90 degrees
    cube.children[2].position.x = parameters.sizeCube * 0.5; // Move to right face

    // Left
    cube.children[3].rotation.y = -Math.PI / 2; // Rotate -90 degrees
    cube.children[3].position.x = -parameters.sizeCube * 0.5; // Move to left face

    // Top
    cube.children[4].rotation.x = -Math.PI / 2; // Rotate -90 degrees
    cube.children[4].position.y = parameters.sizeCube * 0.5; // Move to top face

    // Bottom
    cube.children[5].rotation.x = Math.PI / 2; // Rotate 90 degrees
    cube.children[5].position.y = -parameters.sizeCube * 0.5; // Move to bottom face


    //set initial position
    cube.position.y = -objectsDistance * 0
    cube.position.x = objectsDistance * 0.4


    if (isMobile) {
        cube.position.x = 0
        cube.position.y = 0
    }

    //set initial scale
    cube.scale.set(0, 0, 0)

}




/**
 * Particles
 */
const initParticles = () => {
    const particleTextureCircle = textureLoader.load('/textures/particles/13.png')

    const particlesCount = 400
    const positions = new Float32Array(particlesCount * 3)
    const colors = new Float32Array(particlesCount * 3)

    const colorStart = new THREE.Color(parameters.colorStart)
    const colorFinish = new THREE.Color(parameters.colorFinish)


    for (let i3 = 0; i3 < particlesCount; i3++) {
        positions[i3 * 3] = (Math.random() - 0.5) * objectsDistance * 3
        //change it later to progects.length
        positions[i3 * 3 + 1] = objectsDistance * 0.5 - Math.random() * objectsDistance * 6
        positions[i3 * 3 + 2] = (Math.random() - 0.5) * objectsDistance * 3

        const positionYRelative = Math.abs(positions[i3 * 3 + 1] / (objectsDistance * 5.5))
        // console.log(positionYRelative)

        colors[i3 * 3] = THREE.MathUtils.lerp(colorStart.r, colorFinish.r, positionYRelative)
        colors[i3 * 3 + 1] = THREE.MathUtils.lerp(colorStart.g, colorFinish.g, positionYRelative)
        colors[i3 * 3 + 2] = THREE.MathUtils.lerp(colorStart.b, colorFinish.b, positionYRelative)
    }

    const particlesGeometry = new THREE.BufferGeometry()
    particlesGeometry.setAttribute('position', new THREE.BufferAttribute(positions, 3))
    particlesGeometry.setAttribute('color', new THREE.BufferAttribute(colors, 3))

    const particlesMaterial = new THREE.PointsMaterial({
        size: 0.2,
        sizeAttenuation: true,
        vertexColors: true,
        depthWrite: false,
        transparent: true,
        alphaMap: particleTextureCircle
    })

    const particles = new THREE.Points(particlesGeometry, particlesMaterial)
    scene.add(particles)
}


/**
 * Lights
 */

const directionalLight = new THREE.DirectionalLight(0xffffff, 3)
directionalLight.position.set(1, 1, 0)
scene.add(directionalLight)

const ambientLight = new THREE.AmbientLight(0xffffff, 1)
scene.add(ambientLight)

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () => {
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
//Group
const cameraGroup = new THREE.Group()
scene.add(cameraGroup)

// Base camera
const camera = new THREE.PerspectiveCamera(35, sizes.width / sizes.height, 0.1, 100)
camera.position.z = 6
cameraGroup.add(camera)

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha: true
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))


/**
 * Scroll
 */

let scrollY = window.scrollY
let currentSection = 0

const initScroll = () => {
    const endScale = isMobile ? 0.5 : 1

    window.addEventListener('scroll', () => {
        scrollY = window.scrollY

        //rotating @ span updating var (--rotateAngle)
        document.documentElement.style.setProperty('--rotateAngle', `${scrollY * 0.2}deg`);


        //handling cube rotation and texture change
        const newSection = Math.round(scrollY / sizes.height)

        if (newSection !== currentSection) {
            //if its the first screen, the cube appears:
            if ((currentSection === 0) || (currentSection === 6)) {
                gsap.fromTo(cube.scale, {
                    y: 0,
                    x: 0,
                    z: 0,
                }, {
                    y: endScale,
                    x: endScale,
                    z: endScale,
                    duration: 1,
                    ease: 'elastic.out'
                })
            }
            if ((newSection === 0) || (newSection === 6)) {
                gsap.to(cube.scale, {
                    y: 0,
                    x: 0,
                    z: 0,
                    duration: 1,
                    ease: 'elastic.in'
                })
            }

            currentSection = newSection
            const newProject = projectTextures[currentSection - 1] || projectTextures[0]

            //set material map to the next texture as long as its not the end:
            if (currentSection !== 6) {
                for (let i = 0; i < cube.children.length; i++) {
                    cube.children[i].material.map = newProject[i]
                }
            }


            gsap.to(cube.rotation, {
                x: '+=4',
                y: '+=2',
                duration: 1.5,
                ease: 'elastic.out'
            })

        }
    }
    )
}

initScroll()


/**
 * GSAP
 */

//INTRO
const $intro = document.querySelector('.intro')
const $introTitle = $intro.querySelector('h1')
const $introSubtitle = $intro.querySelector('.subtitle')
const $introDescription = $intro.querySelector('.intro__text')

const tlIntro = gsap.timeline({
    scrollTrigger: {
        trigger: $intro, // Use the current section as the trigger
        start: 'top center',
        end: 'bottom bottom',
    }
}
)
tlIntro.fromTo($introTitle,
    {
        opacity: 0,
        y: 20
    },
    {
        opacity: 1,
        y: 0,
        duration: 0.4,
        ease: 'power2.out'
    })
    .fromTo($introSubtitle,
        {
            opacity: 0,
            y: 20
        },
        {
            opacity: 1,
            y: 0,
            duration: 0.4,
            ease: 'power2.out'
        }, '-=0.1')
    .fromTo($introDescription,
        {
            opacity: 0,
            y: 20
        },
        {
            opacity: 1,
            y: 0,
            duration: 0.4,
            ease: 'power2.out'
        }, '-=0.25')


//PROJECTS
// Select all elements with the class .section--project
const sections = document.querySelectorAll('.section--project');

// Loop through each section and create a timeline
sections.forEach(section => {
    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: section, // Use the current section as the trigger
            start: 'top center',
            end: 'bottom bottom',
        }
    });
    const $title = section.querySelector('h2');
    const $description = section.querySelector('p');

    tl.fromTo($title,
        {
            opacity: 0,
            y: 20
        },
        {
            opacity: 1,
            y: 0,
            duration: 0.5,
            ease: 'power2.out'
        })
        .fromTo($description,
            {
                opacity: 0,
                y: 20
            },
            {
                opacity: 1,
                y: 0,
                duration: 0.4,
                ease: 'power2.out'
            }, '-=0.25')
});

//BUTTONS
// Select all buttons with the class 'button--interactive'
const buttons = document.querySelectorAll('.button--interactive');
const contacts = document.querySelectorAll('.footer__contact');
const elements = [...buttons, ...contacts];

elements.forEach(button => {
    // Mouse enter event
    button.addEventListener('mouseenter', (event) => {
        const { left, top, width, height } = button.getBoundingClientRect();
        const x = event.clientX - left;
        const y = event.clientY - top;
        const xPercent = (x / width) * 100;
        const yPercent = (y / height) * 100;

        // Calculate the angle based on the mouse position
        const angle = Math.atan2(y - height / 2, x - width / 2) * (180 / Math.PI);

        // Apply initial gradient position
        button.style.setProperty('--mouse-x', `${xPercent}%`);
        button.style.setProperty('--mouse-y', `${yPercent}%`);
        button.style.setProperty('--angle', `${angle}deg`);

        // Animate the gradient using GSAP
        gsap.fromTo(button,
            { '--gradient-size': '0%' },
            { '--gradient-size': '150%', duration: 0.3, ease: 'power2.out' }
        );
    });

    // Mouse leave event
    button.addEventListener('mouseleave', () => {
        gsap.to(button, {
            '--gradient-size': '0%',
            duration: 0.3,
            ease: 'power2.in'
        });
    });
});

//Wanna Talk? button
const $wannaTalk = document.querySelector('.talk');
const $talkDetails = document.querySelector('.talk__details');
$wannaTalk.addEventListener('click', () => {
    $talkDetails.setAttribute('style', 'display: flex');
    gsap.fromTo($talkDetails,
        {
            opacity: 0,
            y: -20
        },
        {
            opacity: 1,
            y: 0,
            duration: 0.5,
            ease: 'power2.out'
        }
    );
    setTimeout(() => {
        gsap.fromTo($talkDetails,
            {
                opacity: 1,
                y: 0,
            },
            {
                opacity: 0,
                y: -20,
                duration: 0.5,
                display: 'none',
                ease: 'power2.out'
            }
        );
    }, 6000);
});

/**
 * Cursor
 */
const cursor = {
    x: 0,
    y: 0
}

window.addEventListener('mousemove', (event) => {
    cursor.x = event.clientX / sizes.width - 0.5
    cursor.y = event.clientY / sizes.height - 0.5
}
)

/**
 * Animate
 */
const clock = new THREE.Clock()
let previousTime = 0

const tick = () => {

    if (texturesReady) {
        if (!sceneReady) {
            initCube();
            initScroll();
            initParticles();
            sceneReady = true;
        }


        const elapsedTime = clock.getElapsedTime()
        //doing this for high frequency screens
        const deltaTime = elapsedTime - previousTime
        previousTime = elapsedTime


        //animate cube
        cube.rotation.y += deltaTime * 0.05
        cube.rotation.x += deltaTime * 0.1

        //animate videoTexture:
        // projectTextures[2][1].update()
        // projectTextures[2][3].update()
        // projectTextures[2][5].update()

        //animate camera
        camera.position.y = -scrollY / sizes.height * objectsDistance

        //move cube1
        cube.position.y = isMobile ? (-scrollY / sizes.height * objectsDistance - 1) : -scrollY / sizes.height * objectsDistance

        const parallaxX = cursor.x * 0.5
        const parallaxY = -cursor.y * 0.5
        cameraGroup.position.x += (parallaxX - cameraGroup.position.x) * 5 * deltaTime

        cameraGroup.position.y += (parallaxY - cameraGroup.position.y) * 5 * deltaTime

        // Render
        renderer.render(scene, camera)
    }

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()